@import '../../styles/variables';

.container {
  color: white;

  font-weight: 500;
  font-size: 14px;
  line-height: 16px;

  background: border-box $primary-light;
  padding: 5px 9px;
  border-radius: 10px;
}
.value {
  margin-left: 5px;
  font-weight: 500;
}