@import 'src/styles/variables';

.create-dish {
  width: 440px;
  &-price {
    display: flex;
    align-items: flex-start;
    &-input {
      width: 5rem;
    }
    &-currency {
      font-family: Montserrat, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 32px;
      color: $text-color;
      margin-left: 6px;
      position: absolute;
      left: 100px;
    }
  }
  &-buttons {
    display: flex;
    justify-content: space-between;
  }
}
