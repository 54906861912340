@import 'src/styles/variables';

.daily-menu {
  position: fixed;
  right: 0;
  width: $weekly-menu-width;
  height: calc(100% - #{$header-height});
  padding: 15px 10px;
  background-color: #D7D7D7;
  z-index: 10;
  &-week {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
    &-prev, &-next {
      width: 13px;
      height: 18px;
      cursor: pointer;
      &-hidden {
        cursor: unset;
        visibility: hidden;
        pointer-events: none;
      }
    }
    &-prev {
      background-image: url('./icons/PrevWeek.svg');
    }
    &-next {
      background-image: url('./icons/NextWeek.svg');
    }
    &-title {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      &-name {
        font-weight: bold;
        font-size: 14px;
        color: $text-color;
        margin-bottom: 5px;
      }
      &-dates {
        font-weight: normal;
        font-size: 14px;
        color: $text-color;
      }
    }
  }
  &-empty {
    padding: 6px 7px;
    background: #ECECEC;
    border-radius: 5px;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #6F6F6F;
  }
}

.dishes-card {
  background: white;
  border-radius: 1px;
  border: 1px solid white;
  padding: 8px 10px;
  &[data-drag-active='true'] {
    border: 1px dashed $background-elements;
  }
  &:not(:last-child) {
    margin-bottom: 8px;
  }
  &-header {
    display: flex;
    margin-bottom: 4px;
    &-weekday {
      color: $text-color;
      font-weight: bold;
      font-size: 14px;
      margin-right: 5px;
    }
    &-date {
      font-weight: normal;
      font-size: 14px;
      color: $normal
    }
  }
}

