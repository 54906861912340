.close-button {
  cursor: pointer;
  position: absolute;
  top: -12px;
  right: -12px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.12);
  background: white;
}
