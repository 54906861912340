@import 'src/styles/base';

.toast {
  &-message{
    white-space: nowrap;
    font-size: 14px;
  }
  &-content{
    display: flex;
    flex-flow: row;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    column-gap: 20px;
    color: $text-color;
  }
  &-buttons {
    display: flex;
    flex-direction: row;
    column-gap: 15px;
    justify-content: space-between;
    &-confirm{
      font-weight: 500;
      font-size: 14px;
      cursor: pointer;
      color: $accent-color;

      &:hover {
        color: $accent-hovered;
      }

      &:active {
        color: $accent-active;
      }
    }
    &-close{
      font-weight: 500;
      font-size: 14px;
      text-align: right;
      cursor: pointer;
      color: $normal-hovered;

      &:hover {
        color: $normal-hovered;
      }

      &:active {
        color: $normal-active;
      }
    }
  }
}