@import 'src/styles/variables';

.floating-button {
  cursor: pointer;
  display: flex;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: $accent-color;
  transition: all 0.1s ease;
  &:hover {
    background-color: $primary;
  }
  &:active {
    background-color: $accent-color;
  }
}
