@import 'src/styles/variables';
@import 'src/styles/base';

.helperText {
  @extend .inputErrorText;
  color: $normal;
}

.helperText[data-error='true'] {
  @extend .inputErrorText;
}

.input {
  @extend .base-input;
  &[type='number'] {
    -moz-appearance: textfield;
    &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
  }
}
.inputContainer {
  position: relative;
}
.passwordEye {
  position: absolute;
  right: 6px;
  top: 0;
  height: $input-height;
  display: flex;
  cursor: pointer;
  align-items: center;
  @extend .none-select;
}
