@import '../../styles/variables';

.header {
  display: flex;
  align-items: center;
  position: fixed;
  height: $header-height;
  width: 100%;
  padding: 13px 18px;
  background: #151515;
  z-index: 10;
  &-title {
    flex-grow: 1;
    color: white;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    margin-left: 10px;
  }
  &-logout {
    cursor: pointer;
    color: white;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
  }
}
