@import 'src/styles/variables';

.settings {
  padding: 18px 25px;
  width: 100%;

  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 32px;
    margin-bottom: 20px;
    width: 100%;

    &-title {
      font-weight: bold;
      font-size: 24px;
      line-height: 28px;
      color: $text-color;
    }
  }

  &-contents {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    column-gap: 40px;

    &-column {

    }
  }
}

.fieldsBlock {
  background: $field-background;
  padding: 12px 20px;
}

.separator {
  height:0px;
  border-top: 1px $field-separator solid;
  margin-left: -20px;
  margin-right: -20px;
  padding-bottom: 7px;
}

.buttonsRow {
  margin: 20px 0;
  text-align: right;
}
.workDaysWrapper {
  position: relative;
  margin-top: 5px;
}
.workDaysSelectAll{
  position: absolute;
  right: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;

  color: $accent-color;
  cursor: pointer;
}
