@import 'src/styles/variables';

.aboutUs {
  padding: 18px 25px;
  width: 100%;

  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 32px;
    width: 100%;

    &-title {
      font-weight: bold;
      font-size: 24px;
      line-height: 28px;
      color: $text-color;
    }
  }

  &-contents {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    column-gap: 40px;
    &-column {

    }
  }
}

.infoBlock {
  &:not(:last-child) {
    margin-bottom: 20px;
  }
  position: relative;
  &-title {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    padding: 10px 0;
  }

  &-content {
    background: $field-background;
  }

  &-table {
    flex-grow: 1;
  }
}

.field {
  display: grid;
  grid-template-columns: 150px auto 66px;
  padding: 20px;
  min-width: 100%;
  align-items: start;

  &:not(:last-child) {
    border-bottom: 1px solid $field-separator;
  }

  &-title {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 32px;
  }

  &-textbox {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    flex-grow: 1;
    white-space: pre-wrap;
    align-self: center;

    & > a {
      &:link {
        color: $text-color;
      }
      &:visited {
        color: #444343;
      }
    }
  }

  &-working-hours {
    display: flex;
    flex-flow: column nowrap;

    &-textbox {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      flex-grow: 1;

      &-days {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        flex-grow: 1;
      }

      &-hours {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        flex-grow: 1;
        white-space: pre-wrap;

        &:not(:last-child) {
          margin-bottom: 5px;
        }
      }

      &:not(:last-child) {
        margin-bottom: 5px;
      }
    }

    &-buttons {
      margin-left: auto;
      height: 32px;
      align-self: start;
      display: flex;
    }
  }

  &-buttons {
    margin-left: auto;
    height: 32px;
    align-self: start;
    display: flex;
  }

  &-button {
    cursor: pointer;
    width: 24px !important;
    height: 24px !important;
    border-radius: 50%;
    transition: background-color 0.1s ease;
    display: inline-block;
    align-self: center;

    &[data-type='confirm'] {
      mask: url('./icons/Confrim.svg') no-repeat center center;
      background-color: $submit;

      &:hover {
        background-color: $submit-hovered;
      }

      &:active {
        background-color: $submit-active;
      }
    }

    &[data-type='cancel'] {
      mask: url('./icons/Cancel.svg') no-repeat center center;
      background-color: $normal;

      &:hover {
        background-color: $normal-hovered;
      }

      &:active {
        background-color: $normal-active;
      }
    }

    &[data-type='edit'] {
      mask: url('./icons/Edit.svg') no-repeat center center;
      background-color: $normal;

      &:hover {
        background-color: $normal-hovered;
      }

      &:active {
        background-color: $normal-active;
      }
    }
  }
}


.photoInfoBlock {
  position: relative;
  &-content {
    display: grid;
    grid-template-columns: 1fr auto;
    background: $field-background;
  }
}


.photo-field {
  align-self: stretch;
  height: 220px;
  aspect-ratio: 1;
  margin: 10px;
  overflow: hidden;
  cursor: pointer;
  border-radius: 5px;

  &-overlay{
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    background: rgba($background-elements, 0.5);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    transition: all 0.3s ease;
    &:hover {
      opacity: 1;
    }
    &-content{
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      text-align: center;
      color: white;
      width: 30%;
      &-icon{
        mask: url('./icons/UploadPhoto.svg') no-repeat 4.5px 0;
        background-color: white;
        width: 42px;
        height: 42px;
      }
    }
  }

  &:hover ~ .photo-field-overlay{
    opacity: 1;
  }

  &-wrapper{
    position: relative;
  }

  &-image{
    width: 100%;
  }
}
