@import '../../styles/variables';

.menu-container {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: stretch;
}
.menu-items {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  width: $menu-width;
  height: calc(100% - #{$header-height});
  background: #E8E8E8;
  z-index: 10;
  &-version {
    margin-top: auto;
  }
}
@mixin menu-items-link-style {
  &:hover, &:link, &:active, &:visited {
    @content;
  }
}
.menu-items-link {
  text-decoration: none;
  @include menu-items-link-style {
    color: unset;
  }
}
.menu-items-link-active {
  .menu-item {
    background: white;
    color: $accent-color;
    &-icon {
      &-orders {
        background-image: url('icons/OrdersActive.svg');
      }
      &-dishes {
        background-image: url('icons/DishesActive.svg');
      }
      &-about-us {
        background-image: url('icons/AboutUsActive.svg');
      }
      &-settings {
        background-image: url('icons/SettingsActive.svg');
      }

    }
  }
}
.menu-item {
  height: 60px;
  width: 60px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  color: $border-input;
  &-icon {
    width: 20px;
    height: 20px;
    &-orders {
      background-image: url('icons/Orders.svg');
    }
    &-dishes {
      background-image: url('icons/Dishes.svg');
    }
    &-about-us {
      background-image: url('icons/AboutUs.svg');
    }
    &-settings {
      background-image: url('icons/Settings.svg');
    }
  }
  &-caption {
    margin-top: 3px;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    text-decoration: none;
  }
}
.menu-content {
  flex: 1;
  display: flex;
  margin-left: $menu-width;
}
