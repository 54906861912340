@import 'src/styles/variables';

.title {
  font-family: $font-family;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  align-self: start;
  margin-bottom: 12px;
}

.helper {
  color: #939393;
  position: absolute;
  right: 20px;
  font-size: 14px;
}

.hint {
}

.field {
  display: grid;
  grid-template-columns: 150px auto;
  margin-top: 4px;
  width: 100%;
}

.title {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 32px;
}


.error {
  flex: 1;
  font-size: 14px;
  color: $primary;
  height: 17px;
  margin-bottom: 17px;
  text-align: center;
}
