.preview {
  overflow: hidden;
  height: inherit;
  width: inherit;
  border-radius: 3px;
  &-image {
    width: inherit;
    height: inherit;
    object-fit: cover;
  }
}
