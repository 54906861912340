@import 'src/styles/variables';

.button {
  display: inline-block;
  font-family: $font-family;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  border-radius: 2px;
  border-style: solid;
  border-width: 1px;
  text-align: center;
  cursor: pointer;
  user-select: none;
  transition: all 0.1s ease;
}

.default-button {
  color: white;
  background-color: $accent-color;
  border-style: none;
}

.default-button:hover {
  background-color: $primary-light;
}

.default-button:active {
  background-color: $accent-color;
  color: rgba(white, 0.8);
}

.default-button:disabled {
  color: rgba(white, 0.5);
  background-color: $primary-disabled;
  border-style: none;
  pointer-events: none !important;
  cursor: default;
}

.default-button[data-disabled='true'] {
  @extend .default-button, :disabled;
  color: rgba(white, 0.5);
  background-color: $primary-disabled;
  border-style: none;
  pointer-events: none !important;
}

.primary-button {
  color: $primary;
  background-color: white;
  border-color: $primary;
}

.primary-button:hover {
  background-color: $primary-background;
}

.primary-button:active {
  background-color: white;
  color: $primary-light;
}

.primary-button[data-disabled='true'] {
  cursor: default;
  color: $primary-disabled;
  background-color: white;
  border-color: $primary-disabled;
  pointer-events: none !important;
}

.secondary-button {
  color: $accent-color;
  background-color: $secondary;
  border-color: $secondary;
}

.secondary-button:hover {
  background-color: $secondary-light;
  border-color: $secondary-light;
}

.secondary-button:active {
  background-color: $secondary;
  color: $primary;
}

.secondary-button[data-disabled='true'] {
  color: $secondary-light;
  background-color: white;
  border-color: $secondary-light;
  pointer-events: none !important;
}

.danger-button {
  color: $danger;
  background-color: white;
  border-color: $danger;
}

.danger-button:hover {
  background-color: $danger-background;
}

.danger-button:active {
  background-color: white;
  color: $danger-light;
}

.danger-button[data-disabled='true'] {
  color: $danger-light;
  background-color: white;
  border-color: $danger-light;
  pointer-events: none !important;
}

.content-button {
  padding: 10px 16px;
}

.fullwidth-button {
  padding: 8px 0;
  width: 100%;
}

.icon {
  margin-right: 7px;
}
