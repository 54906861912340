@import 'src/styles/variables';

.root {
  flex: 1;
  display: flex;
  flex-direction: row;
}

.dishes {
  flex-grow: 1;
  padding: 18px 25px;
  margin-right: $weekly-menu-width;
  position: relative;
  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 32px;
    &-title {
      font-weight: bold;
      font-size: 24px;
      line-height: 28px;
      color: $text-color;
    }
    &-search {
      width: 257px;
      background: url(icons/Search.svg) no-repeat scroll 7px 7px;
      padding-left: 30px;
    }
  }
  &-table {
    margin-top: 15px;
    &-bold {
      font-weight: bold;
    }
    &-nowrap {
      white-space: nowrap;
    }
    &-photo {
      width: 32px;
      height: 32px;
    }
    &-edit, &-remove, &-stories {
      cursor: pointer;
    }
    &-tags {
      display: flex;
      &-item {
        margin-right: 5px;
      }
    }
  }
  &-create-button {
    position: fixed;
    bottom: 20px;
    right: 20px + $weekly-menu-width;
    width: 80px;
    height: 80px;
  }
}

.stories {
  display: flex;
  justify-content: center;
  &-count {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    font-size: 12px;
    font-weight: bold;
    color: white;
    background-color: $primary;
  }
}
