.container {
    position: static;
    width: 24px;
    height: 24px;
    left: 0px;
    top: 0px;

    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 30px;

    cursor: pointer;
}

.container > svg {
    opacity: 0.6;
}

.container:active > svg {
    opacity: 0.75!important;
}

.container:hover > svg {
    opacity: 1;
}
