//noinspection CssInvalidAtRule
@import-normalize;

body {
  height: 100%;
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;

  --toastify-toast-width: auto;
}
html {
  height: 100%;
  line-height: unset;
}
*, *:before, *:after {
  outline: none;
  box-sizing: border-box;
}
input:not([type="checkbox"]), textarea {
  -webkit-appearance: none;
}
