@import 'src/styles/variables';
@import 'src/styles/base';

.area {
  @extend .base-input;
  display: block;
  resize: none;
}

.helperText {
  @extend .inputErrorText;
  color: $normal;
}

.helperText[data-error='true'] {
  color: $danger;
}
