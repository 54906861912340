@import 'src/styles/variables';

.stories-popup {
  width: 530px;
  &-story {
    &-preview {
      width: 32px;
      height: 32px;
    }
    &-text {
      font-weight: bold;
      font-size: 14px;
      color: $text-color;
    }
    &-action-button {
      cursor: pointer;
      margin-left: 15px;
    }
    &-add {
      display: flex;
      justify-content: center;
      margin-top: 10px;
      margin-bottom: 10px;
      &-content {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        &-text {
          margin-left: 5px;
          font-size: 14px;
          color: $accent-color;
        }
      }
    }
  }
}

.story-popup {
  width: 530px;
  &-text-area {
    min-height: 72px;
    max-height: 72px;
  }
  &-buttons {
    display: flex;
    justify-content: space-between;
  }
}
