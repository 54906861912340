$primary: #B50014;
$primary-light: rgba($primary, 0.8);
$primary-disabled: rgba($primary, 0.5);
$primary-background: rgba($primary, 0.05);
$primary-link-hover: #00173d;
$secondary: #F9D4D8;
$secondary-light: rgba($secondary, 0.8);
$secondary-link-hover: #32afae;
$accent-color: #DE2034;
$normal: black;
$normal-hovered: #6F6F6F;
$normal-active:#444343;
$danger: #eb5757;
$danger-light: rgba($danger, 0.5);
$danger-background: rgba($danger, 0.05);
$danger-link-hover: #a53d3d;
$border-input: #939393;
$border-disabled: #e9edf4;
$border-hovered: $accent-color;
$border-focused: $primary;
$background: #f5f7fa;
$text-color: #151515;
$background-elements: #6F6F6F;
$accent-hovered: #C21C2E;
$accent-active: #AA1928;

$tick: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");

$font-family: Roboto, Sans-Serif;

$input-height: 32px;
$text-area-height: 72px;

$header-height: 50px;
$menu-width: 60px;
$weekly-menu-width: 300px;

$submit: #1EB47F;
$submit-hovered: #1EB47F;
$submit-active: #147653;
$field-background: #ECECEC;
$field-separator: #D7D7D7;
$light-input-background: #ffffff;
$default-hover: #9cb1d3;
$normal-input-height: 32px;
$input-background: #fbfcfd;
$light-input-background: #ffffff;
$background-input-hover: #f6f8fb;
