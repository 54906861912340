@import 'src/styles/variables';

.confirmation-modal {
  &-text {
    font-weight: normal;
    font-size: 14px;
    color: $text-color;
    margin-bottom: 35px;
  }
  &-buttons {
    display: flex;
    justify-content: space-between;
  }
}
