@import 'src/styles/variables';

.login-wrapper {
  height: 100%;
  background: #ECECEC;
}
.login {
  width: 262px;
  background: white;
  border-radius: 2px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.12);
  &-title {
    font-weight: 500;
    font-size: 18px;
    color: $text-color;
    text-align: center;
    padding: 20px;
    border-bottom: 1px solid #D7D7D7;
  }
  &-form {
    padding: 20px;
  }
  &-submit {
    width: 100%;
  }
  &-version {
    margin-top: 20px;
  }
}
