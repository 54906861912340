@import 'src/styles/variables';
@import 'src/styles/base';

$tag-background-color: #ECECEC;

.tags {
  display: flex;
  flex-wrap: wrap;
  &-select {
    display: none;
  }
  &-tag {
    display: flex;
    align-items: center;
    height: 24px;
    padding: 4px 8px;
    margin-bottom: 4px;
    background-color: $tag-background-color;
    border-radius: 50px;
    border: 1px solid $tag-background-color;
    transition: all 0.1s ease;
    &-text {
      font-size: 14px;
      line-height: 16px;
    }
    &[data-text-color='red'] {
      color: $accent-color;
      &.selected {
        background-color: #FFE1E4;
        &.interactable {
          border: 1px solid #DE2034;
        }
      }
    }
    &[data-text-color='green'] {
      color: #1CC288;
      &.selected {
        background: #C8F7E7;
        &.interactable {
          border: 1px solid #17875F;
        }
      }
    }
    &[data-text-color='orange'] {
      color: #EA6814;
      &.selected {
        background: #FFE6CF;
        &.interactable {
          border: 1px solid #EA6814;
        }
      }
    }
    &[data-text-color='blue'] {
      color: #009CCA;
      &.selected {
        background: #D5F6FF;
        &.interactable {
          border: 1px solid #009CCA;
        }
      }
    }
    &.interactable {
      cursor: pointer;
    }
    & > svg {
      margin-right: 4px;
    }
    &:not(:last-of-type) {
      margin-right: 8px;
    }
  }
  &-error {
    @extend .inputErrorText;
  }
}
