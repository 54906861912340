@import 'src/styles/variables';

.root {
  flex: 1;
  display: flex;
  flex-direction: row;
}

.orders {
  flex-grow: 1;
  padding: 18px 25px;
  margin-right: $weekly-menu-width;
  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 32px;
    &-title-with-date {
      display: flex;
      align-items: center;
      font-size: 14px;
      line-height: 28px;
      color: $text-color;
    }
    &-title {
      font-weight: bold;
      font-size: 24px;
      line-height: 28px;
      color: $text-color;
    }
    &-accept-until {
      margin-left: 15px;
      display: flex;
      height: 28px;
      &-date {
        margin-left: 10px;
        background: #ECECEC;
        border-radius: 5px;
        padding: 6px 8px;
        width: fit-content;
        height: 100%;
        border: unset !important;
        &:hover, &:focus
        {
          border: unset !important;
        }
        &:read-write
        {
          background: #FEE0E3 !important;
        }
      }
      &-button {
        cursor: pointer;
        width: 24px !important;
        height: 24px !important;
        border-radius: 50%;
        transition: all 0.1s ease;
        margin: 2px;
        &[data-type='confirm'] {
          mask: url('./icons/Confrim.svg') no-repeat center center;
          background-color: $submit;
          &:hover {
            background-color: $submit-hovered;
          }
          &:active {
            background-color: $submit-active;
          }
        }
        &[data-type='cancel'] {
          mask: url('./icons/Cancel.svg') no-repeat center center;
          background-color: $normal;
          &:hover {
            background-color: $normal-hovered;
          }
          &:active {
            background-color: $normal-hovered;
          }
        }
        &[data-type='edit'] {
          mask: url('./icons/Edit.svg') no-repeat center center;
          background-color: $normal;
          &:hover {
            background-color: $normal-hovered;
          }
          &:active {
            background-color: $normal-hovered;
          }
        }
      }
    }
    &-states {
      display: flex;
      font-size: 14px;
      color: $text-color;
      &-state {
        cursor: pointer;
        width: 100px;
        overflow: hidden;
        text-align: center;
        padding-bottom: 4px;
        border-bottom: 2px solid #D7D7D7;
        transition: all 0.2s ease-in-out;
        &[data-active='true'] {
          font-weight: bold;
          color: $accent-color;
          border-bottom: 2px solid $accent-color;
        }
      }
    }
  }
  &-table {
    margin-top: 15px;
    &-number {
      color: $text-color;
      font-weight: bold;
      font-size: 24px;
    }
    &-nowrap {
      white-space: nowrap;
    }
    &-button {
      cursor: pointer;
      width: 48px;
      height: 48px;
      border-radius: 50%;
      transition: all 0.1s ease;
      &[data-type='approve'] {
        background: #1EB47F url('./icons/Approve.svg') no-repeat center center;
        &:hover {
          background-color: #1A9E6F;
        }
        &:active {
          background-color: #178A61;
        }
      }
      &[data-type='decline'] {
        background: #C1C1C1 url('./icons/Decline.svg') no-repeat center center;
        &:hover {
          background-color: $normal;
        }
        &:active {
          background-color: $background-elements;
        }
      }
    }
  }
}

.pickup-time {
  display: flex;
  align-items: baseline;
  &-tag {
    margin: 0;
    width: 45px;
    justify-content: center;
  }
}

.dishes {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  &-dish {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 42px;
    padding: 3px;
    background: #ECECEC;
    border-radius: 5px;
    color: $text-color;
    font-weight: bold;
    text-overflow: ellipsis;
    overflow: hidden;
    &:not(:last-of-type) {
      margin-right: 10px;
    }
    &-count {
      font-size: 18px;
      line-height: 21px;
    }
    &-name {
      font-size: 12px;
      line-height: 14px;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}

.customer {
  p {
    min-width: 130px;
    padding: 0;
    margin: 0;
  }
}

.toast{
  &-base{
    background: #FFECEE !important;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05) !important;
    border-radius: 5px !important;
    //top: calc(10px + #{$header-height}) !important;
    //right: calc(10px + #{$weekly-menu-width}) !important;
  }
}