@import 'src/styles/variables';

.file-input {
  &[data-hidden='true'] {
    display: none;
  }
  &-drop-zone {
    cursor: pointer;
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px dashed #939393;
    border-radius: 2px;
    font-size: 14px;
    color: $normal;
    background-color: white;
    transition: all 0.1s ease;
    &[data-active='true'] {
      border-color: $normal;
      background: #ECECEC;
    }
  }
  &-drop-zone:hover {
    background: #ECECEC;
  }
  &-content {
    min-height: 110px;
  }
  &-preview {
    position: relative;
    width: 80px;
    height: 80px;
    &[data-hidden='true'] {
      display: none;
    }
    &-error {
      color: #eb5757;
      font-size: 10px;
    }
  }
}
