@import 'src/styles/base';
@import 'src/styles/variables';

.daily-dish {
  display: flex;
  align-items: center;
  padding: 4px 0;

  &[data-disabled='true'] {
    @extend .disabled;
  }

  &-preview {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }

  &-name {
    color: $text-color;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    max-width: 185px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &-remove {
    width: 20px;
    height: 20px;
    cursor: pointer;
    margin-left: auto;
    background-image: url('icons/RemoveIcon.svg');
  }

  &-info {
    color: $text-color;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    margin-left: auto;
    padding-left: 5px;
  }

  &-up {
    display: flex;
    width: 20px;
    height: 20px;
    cursor: pointer;
    background-image: url('icons/arrowUp.svg');
    background-repeat: no-repeat;
    background-position: center;
    margin-right: 4px;

    background-color: #FFECEE;
    border-radius: 5px;
  }

  &-up:hover {
    background-color: #F4AEB5;
  }

  &-up:active {
    background-color: #EE8B96;
  }

  &-upHidden {
    visibility: hidden;
  }

}
