@import 'src/styles/base';

.table {
  &-header {
    &-cell {
      &-root {
        font-weight: normal !important;
        font-size: 14px !important;
        line-height: 16px !important;
        color: #6F6F6F !important;
        background-color: white !important;
        border-bottom: 1px solid #C1C1C1 !important;
      }
    }
  }
  &-body {
    &-row {
      &-root {
        &[data-disabled='true'] {
          @extend .disabled;
        }
      }
    }
  }
}
