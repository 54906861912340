@import './variables';

.none-select {
  -moz-user-select: none;
  -khtml-user-select: none;
  user-select: none;
  -webkit-user-select: none;
}

.base-input {
  /* !important is needed to be applied to MaterialUI components */
  border: 1px solid $border-input !important;
  border-radius: 2px !important;
  width: 100%;
  height: $input-height;
  padding: 8px 10px;
  font-family: $font-family;
  font-size: 14px;
  line-height: 16px;
  font-weight: normal;
  font-style: normal;
  color: $text-color;
  outline: none;
  transition: all 0.2s ease;
}

.inputErrorBorder {
  border: 1px solid $danger !important;
}
.inputErrorBorder:hover {
  @extend .inputErrorBorder;
}

.inputErrorBorder:focus-within {
  @extend .inputErrorBorder;
}

.base-input[data-error='true'] {
  @extend .inputErrorBorder;
}

.base-input[data-error='true']:hover {
  @extend .inputErrorBorder;
}

.base-input[data-error='true']:focus-within {
  @extend .inputErrorBorder;
}

.base-input:hover {
  border: 1px solid $border-hovered !important;
}
.base-input:focus-within {
  border: 1px solid $border-focused !important;
}
.base-input:active {
  border: 1px solid $border-focused !important;
}
.base-input:disabled {
  border: 1px solid $border-disabled !important;
  color: rgba($normal, 0.3) !important;
}

.base-input::placeholder {
  color: $normal;
}

.inputErrorText {
  width: 100%;
  margin-top: 2px;
  font-weight: normal;
  font-size: 10px;
  height: 12px;
  color: $danger;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}
