@import 'src/styles/base';
@import 'src/styles/variables';

$sold-out-width: 33px;
.weekly-statistics {
  position: fixed;
  right: 0;
  width: $weekly-menu-width;
  height: calc(100% - #{$header-height});
  padding: 15px 10px;
  background-color: #D7D7D7;
  z-index: 10;
  &-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    font-size: 14px;
    color: $text-color;
    &-title {
      font-weight: bold;
    }
    &-total {
      text-align: right;
    }
  }
  &-card {
    display: grid;
    grid-template-columns: min-content auto;
    border-radius: 1px;
    border-right: 4px solid white;
    &:not(:last-child) {
      margin-bottom: 8px;
    }
    &-header {
      display: flex;
      padding: 10px 10px;
      background: white;
      &-weekday {
        &[data-type='present'] {
          color: $accent-color;
        }
        color: $text-color;
        font-weight: bold;
        font-size: 14px;
      }
      &-date {
        font-weight: normal;
        font-size: 14px;
        color: $normal;
        text-align: center;
        flex-grow: 1;
      }
      &-total {
        font-weight: bold;
        font-size: 14px;
        color: $text-color;
      }
      &-sold-out-title {
          padding: 8px 5px;
          overflow: hidden;
          font-size: 14px;
          color: $normal;
          background: #ECECEC;
      }
    }
    &[data-type='past'] {
       @extend .disabled;
     }
    &[data-type='present'] {
      border-right: 4px solid $accent-color;
    }
    &-dish {
      display: grid;
      grid-auto-flow: column;
      grid-auto-columns: min-content auto auto;
      background: white;
      border-radius: 1px;
      border: 1px solid white;
      padding: 0 10px;
      height: 100%;
      align-self: center;
      &-name { }
    }
    &-sold-out {
      padding: 24px 5px;
      width: $sold-out-width;
      &-title {
        padding: 8px 5px;
        overflow: hidden;
        font-size: 14px;
        color: $normal;
        background: #ECECEC;
      }
      &-button {
        padding: 6px;
        background: #ECECEC;
        &-icon {
          cursor: pointer;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          border: 1px solid #C1C1C1;
          transition: all 0.1s ease;
          background: white center center;
          &:hover {
            border: 1px solid #939393;
          }
          &[data-disabled='true'] {
            @extend .disabled;
          }
          &[data-checked='true'] {
            border: 1px solid $accent-color;
            background-image: url('./icons/SoldOutChecked.svg');
          }
        }
      }
    }
  }
  &-modal {
    &-text {
      font-weight: normal;
      font-size: 14px;
      color: $text-color;
      margin-bottom: 35px;
    }
    &-buttons {
      display: flex;
      justify-content: space-between;
    }
  }
}

.loading-wrapper {
  display: grid !important;
}
