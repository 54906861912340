@import 'src/styles/variables';

.title {
  font-family: $font-family;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: $background-elements;
  height: 16px;
}

.helper {
  color: #939393;
  position: absolute;
  right: 20px;
  font-size: 14px;
}

.hint {
}
.field {
  margin-top: 4px;
  width: 100%;
}
.container {
  margin-bottom: 10px;
  min-height: 75px;
}
.title-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 2px;
}

.field-link {
  font-size: 10px;
  line-height: 12px;
}
.error {
  flex: 1;
  font-size: 14px;
  color: $primary;
  height: 17px;
  margin-bottom: 17px;
  text-align: center;
}
.title-with-hint {
  display: flex;
  line-height: 16px;
  div:first-child {
    margin-right: 7px;
  }
  div.hint {
    opacity: 0.5;
    cursor: pointer;
  }
  div.hint:hover {
    opacity: 1;
    path {
      fill: #9cb1d3 !important;
    }
  }
  &[data-required='true']:after {
    content: '*';
    color: $accent-color;
  }
}
